import React, { useReducer } from "react";
import { authReducer, initialState } from "@app/app/authReducer";
import { AppContext, AppDispatchContext } from "@app/app/appContext";
import { BrowserRouter as Router } from "react-router-dom";
import App from "@app/app";

const Root = (props: any) => {
  const [auth, dispatch] = useReducer(authReducer, initialState);
  return (
    <AppContext.Provider value={auth}>
      <AppDispatchContext.Provider value={dispatch}>
        <Router>
          <App />
        </Router>
      </AppDispatchContext.Provider>
    </AppContext.Provider>
  );
};
export default Root;
