import styled from "styled-components"
export const NavWrapper = styled.div`
    height: 54px;
    position: fixed;
    z-index: 999;
    width: 100%;
    >div:first-child{
        width: 235px;
        background-color: #fff;
        box-sizing: border-box;
    }
    >div:nth-child(2) {
        width: 235px;
        background-color: #fff;
    }
`

export const RFPNavWrapper = styled.div`
    height: 50px;
    position: fixed;
    z-index: 999;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2vw;
    border-bottom: 1px solid #004987;
    background: repeating-linear-gradient(
      to right,
      white,
      white 15%,
      #004987 50%,
      #004987 100%
    );
    @media (max-width: 770px) {
      background: repeating-linear-gradient(
        to right,
        white,
        white 20%,
        #004987 50%,
        #004987 100%
      );
    }
      @media (max-width: 560px) {
      background: repeating-linear-gradient(
        to right,
        white,
        white 30%,
        #004987 70%,
        #004987 100%
      );
    }
`

export const HoverButton = styled.button`
  color: #00205c;
  background-color: #e6b712; 
  border: none; 
  border-radius: 30px; 
  padding: 0px 10px;
  font-size: 16px;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5); 
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;

  &:hover {
    color: white;
    background-color: #00205c;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7); 
  }

  svg {
    color: #00205c;
    margin-left: 8px;
    transition: all 0.3s ease; 
    transform: rotate(180deg);
  }

  &:hover svg {
    color: white;
    transform: rotate(180deg);
  }
`;
