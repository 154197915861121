import { ACTION_TYPES } from "@app/app/actions/types";
type initialStateType = {
  user: {
    firstName:  string,
    lastName: string,
    email: string,
    authToken: string
  }
}
export const initialState:initialStateType = {
  user: {
    firstName: '',
    lastName: '',
    email: '',
    authToken: ''
  }
};
export const authReducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.LOGIN: {
      return {...state}
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }

}